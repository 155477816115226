<template>
<v-container>
  <v-card class="mb-5">
    <v-card-title>{{$t("labels.tasks")}}<v-spacer></v-spacer><v-btn icon @click="toggleTaskSortOrder"><v-icon>mdi-swap-vertical</v-icon></v-btn></v-card-title>
    <v-card-text>
      <v-switch
          label="Nur eigene"
          v-model="showMyTasks"
          color="primary"
          disabled
      ></v-switch>
      <v-text-field
          v-if="keycloak.tokenParsed.email === 'rene@codefive.de' || keycloak.tokenParsed.email === 'mhfp@uweg.at'"
          label="Suche"
          v-model="searchText"
          outlined
          @input="filterTasks"
          clearable
          prepend-inner-icon="mdi-magnify"
      ></v-text-field>
    </v-card-text>
    <v-card-actions>
      <v-btn @click="openTaskDialog(null)" outlined>{{$t("labels.newTask")}}</v-btn>
      <v-spacer></v-spacer>
      <v-btn icon @click="getTasks"><v-icon>mdi-sync</v-icon></v-btn>
    </v-card-actions>
    <v-card-actions v-if="user.email == 'rene@codefive.de'">
      <v-btn disabled outlined>{{$t("labels.newTemplate")}}</v-btn>
      <v-btn @click="getTeamTasks" outlined>Team Aufgaben</v-btn>
    </v-card-actions>
  </v-card>
  <v-card v-if="runningTask !== null" class="mb-5" style="background-color: #ebfffa;">
    <v-card-title>
      <v-row>
        <v-col cols="10">
          <span style="word-break: break-word;">{{runningTask.title}}</span>
        </v-col>
        <v-col cols="2" class="text-right">
          <v-menu>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
              ><v-icon>mdi-dots-vertical</v-icon></v-btn>
            </template>

            <v-list>
              <v-list-item @click="openTaskDialog(runningTask)">
                <v-list-item-title>Aufgabe bearbeiten</v-list-item-title>
              </v-list-item>
              <v-list-item :to="`/tasks/${runningTaskId}`" v-if="keycloak.tokenParsed.email == 'rene@codefive.de'">
                <v-list-item-title>Details anzeigen</v-list-item-title>
              </v-list-item>
              <IncidentDialog v-bind:task="runningTask" activator="menuItem"></IncidentDialog>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn :disabled="keycloak === null" fab x-large dark color="#ff55aa" @click="stopTask(runningTask)"><v-icon>mdi-pause</v-icon></v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
    <v-expansion-panels flat style="background-color: #ebfffa;" class="mt-2">
      <v-expansion-panel  style="background-color: #ebfffa;">
        <v-expansion-panel-header>
          <span><v-btn icon outlined><v-icon>mdi-information-variant</v-icon></v-btn></span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col cols="12">{{runningTask.description}}</v-col>
            <v-col cols="6">
              LK: {{getCCLabel(runningTask.supplying_costcenterid)}}, {{getCCName(runningTask.supplying_costcenterid)}}
            </v-col>
            <v-col cols="6">
              EK: {{getCCLabel(runningTask.receiving_costcenterid)}}, {{getCCName(runningTask.receiving_costcenterid)}}
            </v-col>
            <v-col cols="12" v-if="runningTask.jobid">
              <span>Auftrag: {{runningTask.jobid}}</span>
            </v-col>
            <v-col cols="6">
              {{getUsername(runningTask.assignedUser)}}
            </v-col>
            <v-col cols="6">
              Erstellt {{runningTask.created | dateFormatSimple}}
            </v-col>
            <v-col class="text-right" cols="12">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      icon
                      outlined
                      color="primary"
                      class="ma-2"
                      @click="openConfirmFinishTaskDialog(runningTask.id)"
                      v-bind="attrs"
                      v-on="on"
                  ><v-icon small>mdi-check-bold</v-icon></v-btn>
                </template>
                <span>Task abschließen</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
  <v-card v-for="task in tasks" :key="task.id" class="mb-5">
    <v-card-title :id="'tt' + task.id">
      <v-row>
        <v-col cols="10"><span style="word-break: break-word;">{{task.title}}</span></v-col>
        <v-col cols="2" class="text-right">
          <v-menu>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
              ><v-icon>mdi-dots-vertical</v-icon></v-btn>
            </template>

            <v-list>
              <v-list-item @click="openTaskDialog(task)">
                <v-list-item-title>Aufgabe bearbeiten</v-list-item-title>
              </v-list-item>
              <v-list-item :to="`/tasks/${task.id}`" v-if="keycloak.tokenParsed.email == 'rene@codefive.de'">
                <v-list-item-title>Details anzeigen</v-list-item-title>
              </v-list-item>
              <IncidentDialog v-bind:task="runningTask" activator="menuItem"></IncidentDialog>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn :disabled="keycloak === null" fab dark x-large color="secondary" @click="startTask(task)"><v-icon>mdi-play</v-icon></v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
    <v-expansion-panels flat class="mt-2">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span><v-btn icon outlined><v-icon>mdi-information-variant</v-icon></v-btn></span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col cols="12">{{task.description}}</v-col>
            <v-col cols="6">
              LK: {{getCCLabel(task.supplying_costcenterid)}}, {{getCCName(task.supplying_costcenterid)}}
            </v-col>
            <v-col cols="6">
              EK: {{getCCLabel(task.receiving_costcenterid)}}, {{getCCName(task.receiving_costcenterid)}}
            </v-col>
            <v-col cols="12" v-if="task.jobid">
              <span>Auftrag: {{task.jobid}}</span>
            </v-col>
            <v-col cols="6">
              {{getUsername(task.assignedUser)}}
            </v-col>
            <v-col cols="6">
              Erstellt {{task.created | dateFormatSimple}}<br>
              von {{getUsername(task.createdBy)}}
            </v-col>
            <v-col class="text-right" cols="12">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      icon
                      outlined
                      color="primary"
                      class="ma-2"
                      @click="openConfirmFinishTaskDialog(task.id)"
                      v-bind="attrs"
                      v-on="on"
                  ><v-icon small>mdi-check-bold</v-icon></v-btn>
                </template>
                <span>Task abschließen</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <!-- <v-card-text>
      {{task.description}}<br>
      {{task.duration | runtimeFormat}}<br>
      {{getUsername(task.assignedUser)}}<br>
      <v-btn icon @click="toggleInfo(task.id)"><v-icon>mdi-information</v-icon></v-btn>
      <div v-if="toggleTaskInfo === task.id">
        {{task.status}}<br>
        LK: {{getCCLabel(task.supplying_costcenterid)}}, {{getCCName(task.supplying_costcenterid)}}<br>
        EK: {{getCCLabel(task.receiving_costcenterid)}}, {{getCCName(task.receiving_costcenterid)}}<br>
        <span v-if="task.jobid">Auftrag: {{task.jobid}}</span><br>
      </div>
    </v-card-text> -->
    <!-- <v-card-actions>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              icon
              x-large
              class="mr-4"
              @click="openTaskDialog(task)"
              v-bind="attrs"
              v-on="on"
          ><v-icon>mdi-pencil</v-icon></v-btn>
        </template>
        <span>Task bearbeiten</span>
      </v-tooltip> -->
      <!-- <v-btn icon><v-icon>mdi-content-duplicate</v-icon></v-btn> Neues Feature Task duplizieren -->
      <!-- <IncidentDialog v-bind:task="task" v-bind:icon="true" />
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              icon
              x-large
              class="mx-4"
              @click="finishTask(task.id)"
              v-bind="attrs"
              v-on="on"
          ><v-icon>mdi-checkbox-marked-outline</v-icon></v-btn>
        </template>
        <span>Task abschließen</span>
      </v-tooltip>
      <v-spacer></v-spacer>
      <v-btn :disabled="keycloak === null" fab @click="startTask(task)" dark large color="secondary"><v-icon>mdi-play</v-icon></v-btn>
    </v-card-actions> -->
  </v-card>
  <v-dialog
      v-model="taskDialog"
      fullscreen
  >
    <v-card>
      <v-card-title>{{taskDialogTitle}}</v-card-title>
      <v-card-text>
        <v-form
            ref="taskForm"
            v-model="validTaskForm"
        >
          <v-row>
            <v-col cols="12" sm="10" v-if="keycloak.tokenParsed.email == 'rene@codefive.de'">
              <v-autocomplete
                  Label="Aus Vorlage"
                  v-model="selectedVorlage"
                  :items="vorlagen"
                  item-value="title"
                  item-text="title"
                  outlined
                  clearable
                  disabled
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="2" v-if="keycloak.tokenParsed.email == 'rene@codefive.de'">
              <v-btn outlined @click="vorlageLaden" disabled block>Übernehmen</v-btn>
            </v-col>
          </v-row>
          <v-text-field
            label="Titel *"
            v-model="editedTask.title"
            :rules="[rules.required]"
            outlined
          ></v-text-field>
          <v-textarea
              label="Beschreibung"
              v-model="editedTask.description"
              outlined
          ></v-textarea>
          <v-text-field
              label="Dauer"
              v-model="editedTask.duration"
              disabled
              v-if="isAdmin"
          ></v-text-field>
          <v-autocomplete
              v-model="editedTask.supplying_costcenterid"
              :items="costCenters"
              item-text="name"
              item-value="id"
              label="Leistende Kostenstelle"
              :filter="customCostcenterFilter"
              chips
              clearable
              outlined
          >
            <template v-slot:selection="data">
              {{data.item.label}}, {{data.item.name}}
            </template>
            <template slot="item" slot-scope="data">
              {{data.item.label}}, {{data.item.name}}
            </template>
          </v-autocomplete>
          <v-autocomplete
              v-model="editedTask.receiving_costcenterid"
              :items="costCenters"
              item-text="label"
              item-value="id"
              label="Empfangende Kostenstelle *"
              :filter="customCostcenterFilter"
              :rules="[rules.required]"
              required
              chips
              persistent-hint
              clearable
              outlined
          >
            <template v-slot:selection="data">
              {{data.item.label}}, {{data.item.name}}
            </template>
            <template slot="item" slot-scope="data">
              {{data.item.label}}, {{data.item.name}}
            </template>
          </v-autocomplete>
          <v-combobox
              v-model="editedTask.bonuses"
              :items="bonuses"
              item-text="label"
              item-value="id"
              label="Zuschläge"
              multiple
              chips
              outlined
          >
            <template slot="item" slot-scope="data">
              {{data.item.label}} - {{data.item.name}}
            </template>
          </v-combobox>
          <v-row>
            <v-col></v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-none d-sm-flex">
        <v-spacer></v-spacer>
        <v-btn @click="closeTaskDialog" text>Abbrechen</v-btn>
        <v-btn @click="saveTask(false)" :disabled="!validTaskForm" outlined>Speichern</v-btn>
        <v-btn v-if="editedTask.id !== runningTaskId" @click="saveTask(true)" :disabled="!validTaskForm" color="secondary" outlined>Speichern & Starten</v-btn>
      </v-card-actions>
      <v-card-actions class="d-flex d-sm-none">
        <v-row dense>
          <v-col cols="12"><v-btn v-if="editedTask.id !== runningTaskId" @click="saveTask(true)" :disabled="!validTaskForm" color="secondary" outlined block>Speichern & Starten</v-btn></v-col>
          <v-col cols="12"><v-btn @click="saveTask(false)" :disabled="!validTaskForm" outlined block>Speichern</v-btn></v-col>
          <v-col cols="12"><v-btn @click="closeTaskDialog" text block>Abbrechen</v-btn></v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog
      v-model="confirmFinishTaskDialog"
      width="500"
  >
    <v-card>
      <v-card-title>Wirklich abschließen?</v-card-title>
      <v-card-text>
        Task abschließen bestätigen
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="closeConfirmFinishTaskDialog" outlined>Abbrechen</v-btn>
        <v-btn @click="finishTask(selectedTaskIDToFinish)" outlined color="primary">Beenden</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</v-container>
</template>

<script>
import axios from "@/main";
import {mapActions, mapState} from "pinia";
import {piniaStore} from "@/store/pinia";
import IncidentDialog from "@/components/IncidentDialog";
import TimeTrackingApi from "@/apis/TimeTrackingApi";
import WorkspaceDataApi from "@/apis/WorkspaceDataApi";
import TaskBar from "@/components/TaskBar.vue";
const JSJoda = require("@js-joda/core");

function getElementY(query) {
  return window.pageYOffset + document.querySelector(query).getBoundingClientRect().top
}

function doScrolling(element, duration) {
  let startingY = window.pageYOffset
  let elementY = getElementY(element)
  // If element is close to page's bottom then window will scroll only to some position above the element.
  let targetY = document.body.scrollHeight - elementY < window.innerHeight ? document.body.scrollHeight - window.innerHeight : elementY
  targetY = targetY - 70;
  let diff = targetY - startingY
  let start

  if (!diff) return

  // Bootstrap our animation - it will get called right before next frame shall be rendered.
  window.requestAnimationFrame(function step(timestamp) {
    if (!start) start = timestamp
    // Elapsed miliseconds since start of scrolling.
    let time = timestamp - start
    // Get percent of completion in range [0, 1].
    let percent = Math.min(time / duration, 1)

    window.scrollTo(0, startingY + diff * percent)

    // Proceed with animation as long as we wanted it to.
    if (time < duration) {
      window.requestAnimationFrame(step)
    }
  })
}

function scrollToTop(duration) {
  let startingY = window.pageYOffset
  let targetY = 0
  let diff = targetY - startingY
  let start

  if (!diff) return

  // Bootstrap our animation - it will get called right before next frame shall be rendered.
  window.requestAnimationFrame(function step(timestamp) {
    if (!start) start = timestamp
    // Elapsed miliseconds since start of scrolling.
    let time = timestamp - start
    // Get percent of completion in range [0, 1].
    let percent = Math.min(time / duration, 1)

    window.scrollTo(0, startingY + diff * percent)

    // Proceed with animation as long as we wanted it to.
    if (time < duration) {
      window.requestAnimationFrame(step)
    }
  })
}

export default {
  name: "TaskOverview",
  components: {IncidentDialog},
  data: () => ({
    // APIs
    timeTrackingApi: null,
    workspaceDataApi: null,
    // view control
    isMobile: true,
    showInfotext: false,
    dialogDone: false,
    confirmFinishTaskDialog: false,
    isAdmin: false,
    searchText: "",
    validTaskForm: false,
    activateFullscreen: false,
    showMyTasks: true,
    // pinia runningTask: null,
    // pinia runningTaskId: null,
    toggleTasksAscending: false,
    toggleTaskInfo: null,
    tasks: [],
    tasksCopy: [],
    taskDialog: false,
    taskDialogTitle: 'Neue Aufgabe',
    editedTask: {},
    defaultTask: {
      title: '',
      description: '',
      status: 'created',
      bonuses: [],
      receiving_costcenterid: '',
      supplying_costcenterid: '',
    },
    receiving_costcenter: null,
    supplying_costcenter: null,
    hints: {
      title: '',
      supplying_costcenter: '',
      receiving_costcenter: '',
    },
    selectedTaskIDToFinish: "",

    rules: {
      required: value => !!value || 'Erforderlich',
    },

    //vorlagen prototyp
    vorlagen: [
      {
        id: "1",
        title: "Vorlage 1"
      },
      {
        id: "2",
        title: "Vorlage 2"
      },
      {
        id: "3",
        title: "Vorlage 3"
      },
    ],
    selectedVorlage: {
      id: "0",
      title: "",
    },
  }),
  watch: {
    runningTaskId: function (newRunning, oldRunning) {
      if(newRunning !== null) {
        console.log("running task id is now " + newRunning);
      } else {
        console.log("running task id is now null");
        this.getTasks();
      }
    },
    offline: function (newOffline, oldOffline) {
      if(newOffline === false) {
        this.getTasks();
      }
    },
  },
  computed: {
    baseUrl() {
      if (window.location.hostname.startsWith("localhost")) {
        return "http://localhost:50001";
      }
      return "";
    },
    ...mapState(piniaStore, ['bonuses']),
    ...mapState(piniaStore, ['costCenters']),
    ...mapState(piniaStore, ['costCenterMap']),
    ...mapState(piniaStore, ['userMap']),
    ...mapState(piniaStore, ['offline']),
    ...mapState(piniaStore, ['keycloak']),
    ...mapState(piniaStore, ['tasks']),
    ...mapState(piniaStore, ['user']),
    ...mapState(piniaStore, ['runningTask']),
    ...mapState(piniaStore, ['runningTaskId']),
    ...mapState(piniaStore, ['runningTimetrackEventId']),
  },
  methods: {
    customCostcenterFilter (item, queryText, itemText) {
      const name = item.name.toLowerCase()
      const label = item.label.toLowerCase()
      const searchText = queryText.toLowerCase()

      return name.indexOf(searchText) > -1 ||
          label.indexOf(searchText) > -1
    },
    getUsername(id) {
      let u = this.getUserDetailsByID(id);
      if(u !== null) {
        return u.firstname + " " + u.lastname;
      } else {
        return "Unbekannter Benutzer";
      }
    },
    minimizeFullscreen() {
      this.activateFullscreen = false;
    },
    showFullscreen() {
      if (this.activateFullscreen) {
        this.activateFullscreen = false;
      } else {
        this.activateFullscreen = true;
      }
    },
    openTaskDialog(task) {
      if (task) {
        this.taskDialogTitle = 'Aufgabe bearbeiten';
        this.editedTask = Object.assign({}, task);
      } else {
        this.taskDialogTitle = 'Neue Aufgabe';
        this.editedTask = Object.assign({}, this.defaultTask);
      }
      this.taskDialog = true;
    },
    closeTaskDialog() {
      this.taskDialog = false;
      this.taskDialogTitle = 'Neue Aufgabe';
      this.$refs.taskForm.resetValidation();
      this.receiving_costcenter = null;
      this.supplying_costcenter = null;
      this.$nextTick(() => {
        this.editedTask = Object.assign({}, this.defaultTask);
      });
    },
    openConfirmFinishTaskDialog(taskID) {
      this.selectedTaskIDToFinish = taskID;
      this.confirmFinishTaskDialog = true;
    },
    closeConfirmFinishTaskDialog() {
      this.selectedTaskIDToFinish = "";
      this.confirmFinishTaskDialog = false;
    },
    finishRunningTask() {
      axios.post(process.env.VUE_APP_API_BASE_URL + "/api/v1/timetrack/finish", {}, {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}}).then(()=>{
        this.getUserState();
        this.getTasks();
        this.closeConfirmFinishTaskDialog();
      }).catch(()=>{
        this.setErrorMessage("Task konnte nicht abgeschlossen werden.")
      })
    },
    finishTask(id) {
      if(id === this.runningTaskId) {
        this.finishRunningTask();
      } else {
        axios.post(process.env.VUE_APP_API_BASE_URL + "/api/v1/timetrack/finish/" + id, {}, {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}}).then(()=>{
          this.getUserState();
          this.getTasks();
          this.closeConfirmFinishTaskDialog();
        }).catch(()=>{
          this.setErrorMessage("Task konnte nicht abgeschlossen werden.")
        })
      }

      /* console.log('[Cairos] finish task ' + id);
      this.timeTrackingApi.finishTask(id).then((response) => {
            console.log("[Cairos] finished task " + id);
            console.log(response.data);
            if(this.runningTask !== null) {
              if (this.runningTask.id === id) {
                this.setRunningTask(null);
                // delete this.runningTaskId = null;
                this.setRunningTaskBadge(false);
                this.setRunningTaskId(null);
              }
            }
            this.getTasks();
            this.closeConfirmFinishTaskDialog();
          })
          .catch((error) => {
            console.log('[Cairos] error finishing task ' + id);
            console.log(error);
          }); */
    },
    getTasks() {
      this.timeTrackingApi.getUsersTasks(this.toggleTasksAscending).then((res) => {
            console.log(res.data);
            let resultTasks = res.data.filter(e => e.status !== 'finished' );
            if(this.runningTaskId !== null) {
              resultTasks = resultTasks.filter(e => e.id !== this.runningTaskId);
            }
            this.tasks = resultTasks;
            this.tasksCopy = Object.assign([], this.tasks);
            this.setSuccessMessage('Tasks aktualisiert');
          });
    },
    getTeamTasks() {
      this.workspaceDataApi.getMyTeams().then((res) => {
        console.log(res.data);
      });
      //this.timeTrackingApi.getTeamTasks(null);
      this.timeTrackingApi.getUserState().then((res)=>{
        console.log(res.data)
      })
    },
    toggleTaskSortOrder() {
      this.toggleTasksAscending = !this.toggleTasksAscending;
      this.getTasks(this.toggleTasksAscending);
    },
    saveTask(start) {
      this.editedTask.createdBy = this.user.id;
      this.editedTask.assignedUser = this.user.id;
      console.dir(this.editedTask);
      if(this.taskDialogTitle === 'Neue Aufgabe') {
        this.timeTrackingApi.createTask(this.editedTask).then((res)=>{
              if(start) {
                this.startTask(res.data);
              }
            })
            .finally(()=>{
              this.getTasks();
            });
      } else {
        this.timeTrackingApi.updateTask(this.editedTask).then((res)=>{
              if(start) {
                this.startTask(res.data);
              }
            })
            .finally(()=>{
              this.getTasks();
            });
      }
      this.closeTaskDialog();
    },
    startTask(task) {
      this.startTimetrackTask(task.id);
      scrollToTop(300);
      /* this.timeTrackingApi.startTimetrackingTask(task.id).then((res) => {
        this.setRunningTaskId(res.data.runningTaskId);
      }).catch((err) => {
        this.message = "Fehler beim Ermitteln des UserState (Start)"
        this.snackbar = true;
      }) */
    },
    stopTask(task) {
      this.stopTimetrackTask(task.id, this.runningTimetrackEventId);
      /* this.timeTrackingApi.stopTimetrackingTask(id).then((res) => {
        this.setRunningTaskId(res.data.runningTaskId);
      }).catch((err) => {
        this.message = "Fehler beim Ermitteln des UserState (Stop)"
        this.snackbar = true;
      }) */
      this.getTasks();
      setTimeout(() => {
        /*let element = document.getElementById("tt" + task.id);
        let rect = element.getBoundingClientRect();
        window.scrollTo(0, rect.top - 70);*/
        doScrolling('#tt' + task.id, 300);
      }, 300);
    },
    startTaskOld(task) {
      if (task.duration === "") {
        task.duration = null;
      }
      if(this.runningTask) {
        this.timeTrackingApi.stopTask(this.runningTask.id).then(() => {
          this.setRunningTask(null);
          // delete this.runningTaskId = null;
          this.setRunningTaskBadge(false);
          this.setRunningTaskId(null);
          this.timeTrackingApi.startTask(task.id).then((res) => {
            this.setRunningTask(res.data);
            // delete this.runningTaskId = res.data.id;
            this.setRunningTaskBadge(true);
            this.setRunningTaskId(res.data.id);
            this.tasks = this.tasks.filter(e => e.id !== this.runningTask.id);
            //window.scrollTo(0,0);
            scrollToTop(300);
          });
        })
        .finally(()=>{
          this.getTasks();
        });
      } else {
        this.timeTrackingApi.startTask(task.id).then((res) => {
          this.setRunningTask(res.data);
          // delete this.runningTaskId = res.data.id;
          this.setRunningTaskBadge(true);
          this.setRunningTaskId(res.data.id);
          this.tasks = this.tasks.filter(e => e.id !== this.runningTask.id);
          //window.scrollTo(0,0);
          scrollToTop(300);
        });
      }
    },
    stopTaskOld(task) {
      console.dir(task);
      if (task.duration === "") {
        task.duration = null;
      }
      this.timeTrackingApi.stopTask(task.id).then((res) => {
        this.setRunningTask(null);
        // delete this.runningTaskId = null;
        this.setRunningTaskBadge(false);
        this.setRunningTaskId(null);
        this.getTasks();
        setTimeout(() => {
          /*let element = document.getElementById("tt" + task.id);
          let rect = element.getBoundingClientRect();
          window.scrollTo(0, rect.top - 70);*/
          doScrolling('#tt' + task.id, 300);
        }, 100);
      });
    },
    getCCLabel(id) {
      if(id) {
        let cc = this.costCenterMap.get(id);
        return cc.label;
      }
      return '';
    },
    getCCName(id) {
      if(id) {
        let cc = this.costCenterMap.get(id);
        return cc.name;
      }
      return '';
    },
    vorlageLaden() {
      this.editedTask.title = this.selectedVorlage;
      this.editedTask.description = this.selectedVorlage + " Beschreibung";
      this.editedTask.receiving_costcenterid = "8f558135-45b6-48a5-ad04-6ab499544e45";
    },
    onResize () {
      this.isMobile = window.innerWidth < 600
    },
    filterTasks() {
      if(this.searchText === null || this.searchText === '') {
        this.getTasks();
        return;
      }
      //this.tasksCopy = Object.assign([], this.tasks);
      const searchTextLowerCase = this.searchText.toLowerCase();
      this.tasks = this.tasksCopy.filter(task => {
        const titleMatch = task.title.toLowerCase().includes(searchTextLowerCase);
        const descriptionMatch = task.description.toLowerCase().includes(searchTextLowerCase);
        return titleMatch || descriptionMatch;
      });
    },
    ...mapActions(piniaStore, ["getBonuses"]),
    ...mapActions(piniaStore, ["getCostCenters"]),
    //...mapActions(piniaStore, ["getTasks"]),
    ...mapActions(piniaStore, ["getUsers"]),
    ...mapActions(piniaStore, ["getUserState"]),
    ...mapActions(piniaStore, ["getBonusDetailsByID"]),
    ...mapActions(piniaStore, ["getCostCenterDetailsByID"]),
    ...mapActions(piniaStore, ["getRoleDetailsByID"]),
    ...mapActions(piniaStore, ["getTeamDetailsByID"]),
    ...mapActions(piniaStore, ["getUserDetailsByID"]),
    ...mapActions(piniaStore, ["setRunningTaskBadge"]),
    ...mapActions(piniaStore, ["setRunningTask"]),
    ...mapActions(piniaStore, ["setRunningTaskId"]),
    ...mapActions(piniaStore, ["setTasks"]),
    ...mapActions(piniaStore, ["startTimetrackTask"]),
    ...mapActions(piniaStore, ["stopTimetrackTask"]),
    ...mapActions(piniaStore, ["setErrorMessage"]),
    ...mapActions(piniaStore, ["setSuccessMessage"]),
  },
  created() {
    this.timeTrackingApi = new TimeTrackingApi();
    this.workspaceDataApi = new WorkspaceDataApi();
    this.getUserState();
    this.getUsers();
    this.getTasks();
    this.getBonuses();
    this.getCostCenters();
    console.log(this.keycloak);
  },
  mounted() {
    this.onResize()

    window.addEventListener('resize', this.onResize, { passive: true })
  }
}
</script>

<style scoped>

</style>